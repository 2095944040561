// Imports
import { createApp } from 'vue/dist/vue.esm-bundler';
import App from './App.vue';

import router from './lib/routes';
import logger from './lib/logger';
import EventBus from './lib/eventbus';
import utilities from './lib/utilities';

// cache utilities functions
if (utilities){
  logger.log('utilties loaded...', 'pre-app');
}

// create an event bus
const eventbus = new EventBus('global');

// setup app
const app = createApp(App)
app.config.globalProperties.$logger = logger;
app.config.globalProperties.$eventbus = eventbus;

// for dev environment
if (process.env.NODE_ENV === 'development') {
  app.config.performance = true;
  app.config.devtools = true;
}

// setup router and inject app into router
app.use(router);

// before mounting set mobile window height
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// mount
app.mount('#app');

