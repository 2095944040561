<template>
  <div id="background" ref='me' :class="[style, {'dark-mode': data.darkMode}]">
  </div>
</template>

<script>
import utils from '../lib/utilities';

export default {
  name: 'Background',
  props: {
    data: Object
  },
  data: function (){
    return {
      style: 'aboutme'
    };
  },
  mounted: function (){
    this.updateStyle();
    this.$refs.me.addEventListener('transitionend', () => {
      this.$eventbus.emit('bg_end');
    })
  },
  methods: {
    updateStyle: function (){
      const page = utils.parseRoute(this.$route.path);
      if (page === utils.PAGES.EXTRAS){
        let current_style = this.style.split(' ').filter(
          p => (p != utils.PAGES.PROJECT && p != utils.PAGES.ERROR));
        current_style.push(utils.PAGES.EXTRAS);
        this.style = current_style.join(' ');
      } else if (page === utils.PAGES.PROJECT){
        this.style = [utils.PAGES.PROJECTS, utils.PAGES.PROJECT].join(' ');
      } else {
        this.style = page;
      }
    }
  },
  watch: {
    '$route.path': function (){
      this.updateStyle();
    }
  }

};
</script>

<style scoped lang="scss">

#background{
  position: sticky;
  left: 0px;
  top:0px;
  width: 100%;
  @include viewPortHeight;
  z-index: -5;
  background-color: unset;
  background: $background;
  background-size: 600% 100%;
  background-position: 0% 100%;
  @include transition(height $slow-animation-ms $default-animation-style,
                      background-position $slow-animation-ms $default-animation-style,
                      opacity $slow-animation-ms $default-animation-style);

  &.aboutme{
    background-position: 00% 100%;
    height: calc(66px + (100% - 66px)* #{$aboutme-top-height});
    @media only screen and (max-width: $mobile-width){
      height: calc(66px + (100% - 66px)* #{$aboutme-top-height-mobile});
    }
  }

  &.workexp {
    background-position: 40% 100%;
    height: calc(66px + (100% - 66px)* #{$workexp-top-height});
    @include transform(rotate(0deg));
  }

  &.projects {
    background-position: 100% 100%;
    @include viewPortHeight;
  }

  &.project,
  &.error,
  &.extras{
    @include viewPortHeight;
  }

  &.project,
  &.error{
    opacity: 0;
  }

  &.extras{
    background: black;
  }

  @media only screen and (max-width: $mobile-width){
    background: $background-mobile;
    background-size: 600% 100%;
    background-position: 0% 100%;
  }
}
</style>
