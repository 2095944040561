<template>
  <div class="page" id="projects-page">
    <div class="projs-page-container">
      <div class="projs-container-inner">
        <div
          class="project-thumbnail"
          v-for="(proj, index) in projects"
          :key="index"
          :class="{ hasThumbnail: proj.pid in projectsImages }"
          :style="projImagesCss(proj.pid)"
          @click="navigateToIndex(proj.pid)"
        >
          <div class="project-thumbnail-inner">
            <span class="project-tag" :class="'pt-'+proj.tag_index">{{ proj.tag }}</span>
            <div class="project-title q-font f-bold">{{ proj.title }}</div>
            <div class="project-subtitle q-font f-light">
              {{ proj.subtitle }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const logger_tag = "ProjectsPage";
import utils from "../lib/utilities";
// import SimpleBar from 'simplebar';

export default {
  name: logger_tag,
  props: {
    links: Object,
    data: Object,
  },
  mounted: function () {
    this.tags.forEach(tag => {
      console.log(tag);
    });
  },
  computed: {
    tags: function () {
      return this.data.aboutme.config["projectTags"];
    },
    projects: function () {
      let projs = [];
      Object.entries(this.data.projects).forEach(([pid, proj]) => {
        proj.pid = pid;
        proj.tag_index = this.tags.indexOf(proj.tag);
        projs.push(proj);
      });
      return projs;
    },
    projectsImages: function () {
      return this.links.projects;
    },
  },
  methods: {
    projImagesCss: function (pid) {
      if (pid in this.projectsImages) {
        let first_key = Object.keys(this.projectsImages[pid].path).sort()[0];
        return {
          "--src": `url(${this.projectsImages[pid].path[first_key]})`,
        };
      }
      return {};
    },
    navigateToIndex: function (index) {
      this.$router.push({ name: utils.PAGES.PROJECT, params: { id: index } });
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  height: auto;
}

.project-thumbnail-inner {
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(87, 87, 87, 0.58) 0%,
    rgba(64, 64, 64, 0.58) 100%
  );
  @include no-select;
}

.project-tag {
  margin-bottom: auto;
  align-self: flex-end;
  margin-right: 3px;
  margin-top: 3px;
}

.project-subtitle {
  margin-bottom: auto;
}

.project-thumbnail {
  min-width: 24%;
  padding-top: 15%;
  position: relative;
  height: 0%;
  border-radius: 5px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include box-sizing(border-box);
  border: solid 1px transparent;
  @include transition(box-shadow $fast-animation-ms $default-animation-style);

  cursor: pointer;

  &.hasThumbnail {
    background-image: var(--src);
  }

  &:hover {
    // box-shadow: inset 0 -8px 6px -6px $c-black;
    border: solid 1px black;
    @include transition(border $fast-animation-ms $default-animation-style);
  }

  &:active {
    @include box-shadow(inset 0 0 10px $c-black);
  }

  @media only screen and (max-width: $mobile-width) {
    min-width: 44%;
    padding-top: 63%;
  }
}

.projs-page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 40px;
}

.projs-container-inner {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 10px;
  height: 100%;
  flex-wrap: wrap;
  gap: 12px 12px;
  &::after {
    width: 2vw;
    height: 10px;
    display: inline-block;
    content: " ";
    flex-shrink: 0;
  }

  @media only screen and (max-width: $mobile-width) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
</style>
