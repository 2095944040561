<template>
  <div  class="image-frame-container">
    <div ref='frame' class="image-frame" :class="{'loaded':loaded, 'hide':hide}"></div>
  </div>
</template>

<script>

export default {
  name: 'ImageFrame',
  props: {
    src: String,
    acceptPortrait: {
      type: Boolean,
      default: true
    },
  },
  data: function(){
    return {
      loaded: false,
      hide: false
    }
  },
  methods: {
    updateToImage: function(){
      let frame = this.$refs.frame;
      if (frame == null){
        return;
      }
      let ratio = this.image.naturalHeight/this.image.naturalWidth;
      if (ratio >= 1 && this.acceptPortrait){
        // frame.style.height = '100%';
        // frame.stlye.width = '100%';
        frame.style.paddingTop = '100%';
      }else if (ratio < 1){
        frame.style.paddingTop = `${ratio*100}%`;
      }
      frame.style.backgroundImage = `url('${this.src}')`;
      this.loaded = true;
      this.image = null;
      this.hide = false;
    }
  },
  created: function(){
    let image = new Image();
    image.src = this.src;
    this.image = image;

    this.skipLoading = image.complete;
  },
  mounted: function(){
    if (!this.skipLoading){
      this.image.onload = this.updateToImage.bind(this);
    }else{
      this.updateToImage();
    }
  },
  watch: {
    src: function(){
      this.hide = true;
      let image = new Image();
      image.src = this.src;
      this.image = image;

      this.skipLoading = image.complete;
      if (this.skipLoading){
        setTimeout(function(){
          this.updateToImage();
        }.bind(this), 150)
      }else{
        this.$refs.frame.backgroundImage = '';
        this.image.onload = this.updateToImage.bind(this);
      }
    }
  }
};
</script>

<style scoped lang="scss">
$height-ratio: 56.25%;

.image-frame-container{
  display: flex;
  max-width: 100%;
  // height: 100%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.image-frame{
  width: 100%;
  padding-top: $height-ratio;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include transition(padding-top $fastest-animation-ms,
                      opacity $fastest-animation-ms);
  border-radius: $global-border-radius;
  opacity: 1;
  height: 0%;
}

.image-frame.hide{
  opacity: 0;
}

.image-frame::before{
  width: 100%;
  padding-top: $height-ratio;
  margin-top: -$height-ratio;
  background: $c-subtle;
  content: '';
  display: block;
  opacity: 1;
  @include transition(opacity $fast-animation-ms);
  height: 0;
}

.image-frame.loaded::before{
  opacity: 0;
}


</style>
