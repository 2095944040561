<template>
  <div class="page" id="aboutme-page">
    <div id="aboutme-top" class="page-top">
      <div class="slide-show only-on-desktop">
        <ImageFrame
          :src=currentImage />
      </div>
      <div class="info-box">
        <div class="info-text f-regular  q-font">
          <span class="s-font f-bold pad-bottom">Who I am:</span>
          {{data.aboutme.who_i_am.value}}
          <span class="s-font f-bold pad-bottom">What I can do: </span>
          {{data.aboutme.what_i_can_do.value}}
        </div>
        <div class="skill-box">
          <div class="divider"></div>
          <div class="title s-font f-bold">SKILLS</div>
          <div class="skill-box-wrapper">
            <div class="skill-inner-box" v-for="category in skill_names" :key="category">
              <div class="skill-inner-box-title f-bold i-font">{{category}}</div>
              <transition-group name="vlistup">
                <div class="skill-inner-box-skills i-font f-light"
                  v-for="(skill, index) in data.aboutme.skills[category]" :key="skill"
                  :style="{'--animation-order': index + 1}">
                  • {{skill}}
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="aboutme-bottom" class="page-bottom">
       <div class="slide-show only-on-mobile">
        <ImageFrame
          :src=currentImage />
      </div>
      <ButtonBox :buttons="buttons" />
    </div>
  </div>
</template>

<script>
const logger_tag = 'AboutMePage';
import ImageFrame from '../components/ImageFrame';
import ButtonBox from '../components/ButtonBox';

let buttons = [
  {
    'name': 'LinkedIn',
    'link': 'https://www.linkedin.com/in/arunaruljothi/',
    'class': 'cb-linkedin'
  },
  {
    'name': 'Github',
    'link': 'https://github.com/aaruljothi',
    'class': 'cb-github'
  },
    {
    'name': 'Instagram',
    'link': 'https://github.com/aaruljothi',
    'class': 'cb-instagram'
  },
]

export default {
  name: logger_tag,
  components: {
    ImageFrame,
    ButtonBox
  },
  props: {
    links: Object,
    data: Object
  },
  data: function (){
    return {
      all_skills: {},
      currentImage: ''
    };
  },
  created: function (){
    this.currentImage = this.links.aboutme.path[this.pageImages[0]];
    if (this.data.darkMode){
      buttons[1].class = 'cb-github-dark';
    }
    this.buttons = buttons;
  },
  mounted: function (){
    let c = 1;
    let numPics = this.pageImages.length - 1;
    setInterval(function(){
      this.currentImage = this.links.aboutme.path[this.pageImages[c]];
      c = (c == numPics) ? 0 : c + 1;
    }.bind(this), 5000)
  },
  computed: {
    skill_names: function(){
      return this.data.aboutme.config.skills_order;
    },
    pageImages: function(){
      // sorted ref to page images
      let keys = Object.keys(this.links.aboutme.path).sort();
      return keys;
    }
  }
};
</script>

<style scoped lang="scss">
#aboutme-page{
  #aboutme-top{
    display: flex;
    flex-direction: row;

    .slide-show{
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 5%;
    }

    .info-box{
      flex-grow: 1;
      max-width: 53%;
      min-width: 53%;
      padding: 15px 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      font-size: calc(#{$navbar-font-size} * .6);
      text-align: justify;

      .info-text{
        // margin-top: -15%;
        padding-bottom: 15px;
      }

      .skill-box{
        .divider{
          background-color: black;
          height: 3px;
          margin: 5px 20%;
          border-radius: 5px;
        }

        .title{
          padding-top: 10px;
          color: black;
          text-align: center;
          font-size: calc(#{$navbar-font-size} * .5);
        }

        .skill-box-wrapper{
          font-size: calc(#{$navbar-font-size} * .5);
          display: flex;
          flex-direction: row;
          padding-top: 20px;
          color: black;

          .skill-inner-box{
            flex-grow: 1;
            text-align: left;

            .skill-inner-box-title{
              padding-bottom: 5px;
              text-align: center;
            }

            .skill-inner-box-skills{
              padding-left: 10%;
              animation: animate-fade-in $fast-animation-ms $default-animation-style
                calc(var(--animation-order) * 100ms) 1 normal both;
            }
          }
        }
      }
    }
  }

  #aboutme-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .button-box{
      // flex-grow: 1;
      width: 100%;
      justify-content: space-evenly;
    }
  }

  @media only screen and (max-width: $mobile-width){
    #aboutme-top{
      .info-box{
        flex-grow: 1;
        max-width: 100%;
        min-width: auto;
        font-size: 1.1rem !important;
      }
      .skill-box{
        display: none;
      }
    }
    .button-box{
      flex-direction: column;
      margin-right: 0px;
      margin-left: 15px;
    }

    #aboutme-bottom{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 5%;

      .button-box{
        height: 100%;
        width: 40%;
        justify-content: space-evenly;
      }
      .slide-show{
        flex-grow: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 8px;
      }
    }
  }

  @media screen and ( max-height: 780px ){
    .skill-box{
      display: none;
    }
  }

}
</style>
