<template>
  <div class="page" :class="{'dark-mode': data.darkMode}" ref="page">
    <div class="markdown-wrapper" ref="wrapper">
      <TOCVue v-show="pageOverflowing" :toc="toc" :mobile="data.mobile"/>
      <div class="markdown-body" ref="content"></div>
    </div>
  </div>
</template>

<script>
const logger_tag = 'SingleProjectPage'
// import utils from '../lib/utilities';
import convertMarked from '../lib/md';
import sass from '../styles/_base.scss';
import TOCVue from '../components/TOC.vue';

export default {
  name: logger_tag,
  components:{
    TOCVue
  },
  props: {
    links: Object,
    data: Object,
  },
  data: function(){
    return {
      content: "There is nothing here yet. Please check back later!",
      toc: [],
      pageOverflowing: false
    }
  },
  mounted: function(){
    this.setMarkdownContent();
    this.titleImages();

    let scrollMonitor = function(){
      if ((this.$refs.page.scrollTop > parseInt(sass.mdTopPadding)) || this.data.mobile){
        this.$refs.wrapper.classList.add('hard');
        this.$refs.page.removeEventListener("wheel", scrollMonitor);
      }
    }.bind(this);
    this.$refs.page.addEventListener("wheel", scrollMonitor, {passive: true})
    
    if (this.$route.hash){
      document.getElementById(this.$route.hash.slice(1)).scrollIntoView({behavior:"smooth"});
    }
  },
  computed: {
    projectsObject:function(){
      let ret = {};
      Object.entries(this.data.projects).forEach(([pid, proj]) => {
        ret[pid] = proj;
      });
      return ret;
    },
    currentIndex: function(){
      return this.$route.params.id;
    },
    currentProject: function(){
      return this.projectsObject[this.currentIndex];
    },
    markdownContent: function(){
      return this.currentProject.page_markdown; 
    },
    projectImages: function(){
      return this.links.projects[this.currentIndex].path;
    }
  },
  methods: {
    setMarkdownContent: function(){
      if (this.markdownContent != undefined){
        let markedData = convertMarked(this.markdownContent, this.projectImages);
        this.content = markedData.html;
        this.toc = markedData.toc;
      }
      this.$refs.content.innerHTML = this.content;
      this.checkPageOverflowing();
    },
    titleImages: function(){
      let imgs = document.getElementsByTagName('img');
      for (const img of imgs){
        let title = img.getAttribute('title');
        if ((title != undefined) && title != null){
          let el = document.createElement("p");
          el.classList.add('img-name');
          el.innerHTML = title;
          img.after(el);
        }
      }
    },
    checkPageOverflowing: function(){
      this.pageOverflowing = this.$refs.page.clientHeight < this.$refs.content.clientHeight;
    }
  }
}
</script>

<style scoped lang="scss">

.page{
  @include max-width(false);
  margin-top: 0;
  padding-top: $navbar-height;
  display: block;
}

</style>
