import { initializeApp } from "firebase/app"
import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

import logger from './logger';

const firebaseConfig = {
  apiKey: 'AIzaSyCmEaPpUZxgMQz6cj0UZajgGrZM2cCvCy0',
  authDomain: 'aa-personal-site-70be5.firebaseapp.com',
  databaseURL: 'https://aa-personal-site-70be5.firebaseio.com',
  projectId: 'aa-personal-site-70be5',
  storageBucket: 'aa-personal-site-70be5.appspot.com',
  messagingSenderId: '792644613961',
  appId: '1:792644613961:web:aec85a16d167a3614e0c98'
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const storageRef = ref(storage);

// functions
async function fetchObject (c){
  const data = {};
  const q = query(collection(db, c));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data[doc.id] = doc.data();
  });
  return data;
}

async function fetchArray (c){
  const data = [];
  const q = query(collection(db, c));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}

/**
 * fetches all links for items inside the folder in path, with recursive on will
 * go through folders
 * @param {str} path
 */
async function fetchStorageLinks (path, recursive = false){
  try {
    let res = await listAll(ref(storageRef, path));
    if (res.prefixes.length > 0 && !recursive){
      logger.warn(`When fetching path ${path}, found some folders, will not report!`,
        'fetchStorageLinks');
    }
    if (recursive){
      const ret = {};
      res.prefixes.forEach(async function (folderRef){
        res = await listAll(folderRef);
        const links = await fetchStorageHelper(res);
        ret[folderRef.name] = links;
      });
      return ret;
    } else {
      const links = await fetchStorageHelper(res);
      return {
        path: links
      };
    }
  } catch (e){
    logger.log(e, 'fetchStorageLinks', logger.levels.ERROR);
    return {};
  }
}

async function fetchStorageHelper (res){
  const links = {};
  res.items.forEach(async (itemRef) => {
    const url = await getDownloadURL(itemRef);
    links[itemRef.name] = url;
  });
  return links;
}

// wrappers
async function getAboutmeData (){
  const data = await fetchObject('aboutme');
  return data;
}

async function getWorkexpData (){
  return await fetchArray('workexp');
}

async function getProjectsData (){
  return await fetchArray('projects');
}

async function getAboutmeStorage (){
  return await fetchStorageLinks('aboutme');
}

async function getWorkexpStorage (){
  return await fetchStorageLinks('workexp', true);
}

async function getProjectStorage (folders){
  let ret = {};
  for (const f of folders){
    ret[f] = await fetchStorageLinks(`projects/${f}`);
  }
  return ret;
}

// export objects

const DB_FUNCS = {
  getAboutme: getAboutmeData,
  getWorkexp: getWorkexpData,
  getProjects: getProjectsData
};

const STORAGE_FUNCS = {
  getAboutme: getAboutmeStorage,
  getWorkexp: getWorkexpStorage,
  getProject: getProjectStorage,
  getProjects: getProjectStorage
};


export {
  DB_FUNCS,
  STORAGE_FUNCS
};
