// simple logger with 3 levels info, warn, and error

// TODO: use the dot env pull here
// const current_env = 'debug';

const LOG_LEVEL = {
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error'
};

const LOG_DEBUG = true;

function log (msg, tag=null, level=LOG_LEVEL.INFO){
  // if (current_env != 'debug'){
  //   return;
  // }
  // if (tag === null || tag === undefined){
  //   tag = 'unknown';
  // }else{
  //   // if (tag.$options != undefined){
  //   //   // tag = tag.$options.name + '_' +tag.$options.__hmrId;
  //   // }
  // }
  tag = tag || 'unknown'; 
  switch (level){
    case LOG_LEVEL.INFO:
      console.info(`${tag}:::${msg}`);
      break;
    case LOG_LEVEL.WARN:
      console.warn(`${tag}:::${msg}`);
      break;
    case LOG_LEVEL.ERROR:
      console.error(`${tag}:::${msg}`);
  }
}

const logger = {
  log: log,
  warn: function (msg, tag=null){
    log(msg, tag, LOG_LEVEL.WARN);
  },
  error: function (msg, tag=null){
    log(msg, tag, LOG_LEVEL.ERROR);
  },
  levels: LOG_LEVEL,
  debug: LOG_DEBUG
};

export default logger;
