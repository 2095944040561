<template>
  <div ref='box' class="button-box dflex" :class="direction">
    <component class="button" v-for="(button, index) in buttons"
      :is=buttonTypes[index]
      :key=button.name
      :class=button.class
      :button=button
      @click="throwEvent(button)"
      >
      {{button.name}}
    </component>
  </div>
</template>

<script>
const logger_tag = 'ButtonBox'
// buttons: [
//   {
//     'name':'name',
//     'link': '', [FOR EXTERNAL LINKS]
//     'event':'', [FOR EVENTS EMITTED VUE STYLE AND BUS],
//     'to': '',   [FOR INTERNAL LINKS]
//     'class':'',
//   }
// ]

// components

const RouterComp = {
  name: 'RouterComp',
  template: `<div @click=moveTo(button.to) >{{button.name}}</div>`,
  props: { button: Object },
  methods: {
    moveTo: function(loc){
      this.$router.push(loc);
    }
  }
};

const LinkComp = {
  name: 'LinkComp',
  template: `<a :href=button.link >{{button.name}}</a>`,
  props: { button: Object }
};

const EventComp = {
  name: 'EventComp',
  template: `<div>{{button.name}}</div>`,
  props: { button: Object }
}

export default {
  name: logger_tag,
  components: {
    RouterComp,
    LinkComp,
    EventComp,
  },
  props: {
    direction: {
      type: String,
      default: 'row'
    },
    buttons: Array,
    style: String
  },
  computed: {
    buttonTypes: function(){
      let bttypes = []
      for (const b of this.buttons){
        if ('link' in b){
          bttypes.push(LinkComp);
        }else if ('to' in b){
          bttypes.push(RouterComp)
        }else{
          bttypes.push(EventComp);
        }
      }
      return bttypes;
    }
  },
  methods: {
    throwEvent: function(b){
      if ('event' in b){
        this.$emit(b.event);
        this.$eventbus.emit(b.event);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.button-box{
  padding: 5px 10px 5px 10px;
  justify-content: space-evenly;

  &.full{
    height:100%;
  }
}
</style>
