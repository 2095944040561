<template>
  <div class="page" id="extras-page">
    <input id="prompt" ref="prompt" type="password" @focus="focusOn" @blur="focusOff" @keydown="onKey">
    <div class="prompt-bg" ref="promptBG"></div>
  </div>
</template>

<script>

export default {
  name: 'ExtrasPage',
  props: {
  },
  methods: {
    focusOn: function(){
      this.$refs.promptBG.classList.add('active');
    },
    focusOff: function(){
      this.$refs.promptBG.classList.remove('active');
    },
    onKey: function(){
      let p = this.$refs.prompt.value;
      let n = (Math.pow(0.9, p.length) * 3) + 's';
      this.$refs.promptBG.style.setProperty('--blink', n);
    }
  }
}
</script>

<style scoped lang="scss">
#extras-page{
  align-items: center;
  justify-content: center;
}
.prompt-bg{
  border-bottom: solid 1px white;
  width: 40%;
  height: 2.1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%, -50%));
  @include transition(border $fast-animation-ms $default-animation-style);
  --blink: 3s;
  &.active{
    border: solid 1px white;
    border-radius: 3px;
    animation: blinker var(--blink, 3s) linear infinite;
  }
}
#prompt{
  width: 40%;
  background: transparent;
  color:white;
  outline: none;
  width: 40%;
  font-size: 2rem;
  border: none;
  caret-color: transparent;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%, -50%));
  z-index: 99;
}


@keyframes blinker {
  100% {
    opacity: 0;
  }
}

</style>
