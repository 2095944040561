<template>
  <div class="full-page" id="landing-page" :style="{background: bgColor, color: fgColor}">
      <div class="landing-wrapper">
        <div class="brand q-font f-medium">arun aruljothi</div>
        <LoadingBar
          :loadingColor=bgColor
          :currentPercent=currentPercent
          :minimumWaitSeconds=2
          @complete=moveOn
        />
      </div>
  </div>
</template>

<script>
import LoadingBar from '../components/LoadingBar';

export default {
  name: 'LandingPage',
  components: {
    LoadingBar
  },
  props: {
    data: Object
  },
  data: function (){
    return {
      bgColor: this.data.bgColor,
      fgColor: this.data.fgColor
    };
  },
  computed: {
    currentPercent: function (){
      return this.data.loadedPercent;
    }
  },
  methods: {
    moveOn: function (){
      if (this.$router.lastLoaded.path != '/'){
        this.$router.push(this.$router.lastLoaded.fullPath);
      } else{
        this.$router.push(this.data.onLoaded);
      }
      this.$logger.log('data loaded...');
    }
  }
};
</script>

<style scoped lang="scss">
#landing-page{
  background: white;
  color: black;

  .landing-wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -60%))
  }

  .brand{
    font-size: 72px;
    text-align: center;
  }
}

// mobile styles
@media only screen and (max-width: $mobile-width){
  #landing-page{
    .landing-wrapper{
      width: 75vw;
    }
    .brand{
      font-size: 48px;
    }
  }
}
</style>
