<template>
  <div class="toc-comp" @click="toggle">
      <div class="toc-wrapper" :class="{'opened': showContent}">
        <transition name="vfade" >
          <div class="toc-content" v-show="showContent">
            <div v-for="t in cleanedToc" :key="t.anchor" :class="'toc-h-'+t.level">
              <a :href="'#'+t.anchor" >{{t.levelLabel}} {{t.text}}</a>
            </div>
          </div>
        </transition>
        <span class="toc-title">
          <p v-if="!showContent">{{openString}}</p>
          <p v-else>{{closeString}}</p>
        </span>

      </div>

  </div>
</template>

<script>
const logger_tag = 'toc-comp'

export default {
  name: logger_tag,
  props: {
      toc: Object,
      mobile: Boolean
  },
  data: function(){
    return {
      showContent: false,
      openString: "Table of Contents",
      closeString: "Close"
    }
  },
  mounted: function(){
    if (this.mobile){
      this.openString = "TOC";
      this.closeString = "close";
    }
  },
  computed: {
    cleanedToc: function(){
      return this.toc.map(t => {
        if (t.text.startsWith("<")){
          t.text = t.text.match(/>.*?</g).map(s => s.slice(1, -1)).join('');
        }
        return t;
      })
    }
  },
  methods: {
    toggle: function(){
      this.showContent = !this.showContent;
    }
  }
}
</script>

<style scoped lang="scss">
.toc-comp{
  @include no-select;
  @include box-sizing(border-box);
  cursor: pointer;
  max-width: $md-max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 45px;
  margin-bottom: -2em;
  @media only screen and (max-width: $mobile-width){
    padding: 0 15px;
    margin-bottom: -3em;
  }
}

.toc-wrapper{
  align-self: flex-end;
  max-width: 220px;
  border: solid 2px black;
  padding: 2px 5px;
  max-height: calc(2rem + 15px);
  @include transition(max-height $fast-animation-ms $default-animation-style);

  &.opened{
    max-height: 900px;
  }
}


.dark-mode .toc-wrapper{
  border-color: white;
}

.toc-title{
  // align-self: flex-end;
  color: $c-blue2;
  text-transform: uppercase;
}

.opened{
  .toc-title{
    @media only screen and (max-width: $mobile-width){
      text-align: right;
    }
  }
}

.toc-content{
  // align-self: flex-end;
  // width: 164px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: dashed 1px black; 
  @for $i from 1 through 5{
    .toc-h-#{$i}{
      padding-left: calc(20px * calc(#{$i} - 1));
      color: $c-blue2;
    }
  }
}

.dark-mode .toc-content{
  border-color: white;
}
</style>
