import logger from './logger';

//events - a super-basic Javascript (publish subscribe) pattern

class EventBus{
  constructor(name, log=false){
      this.events = {};
      this.name = name;
      this.log = log;
  }

  on(eventName, fn) {
      this.events[eventName] = this.events[eventName] || [];
      this.events[eventName].push(fn);
  }

  off(eventName, fn) {
      if (this.events[eventName]) {
          for (var i = 0; i < this.events[eventName].length; i++) {
              if (this.events[eventName][i] === fn) {
                  this.events[eventName].splice(i, 1);
                  break;
              }
          }
      }
  }

  emit(eventName, data=null) {
      logger.log(`event: ${eventName} was fired with data ${data}`, 'eventbus');
      if (this.events[eventName]) {
          this.events[eventName].forEach(function(fn) {
              fn(data);
          });
      }
  }
}

export default EventBus;
