/* eslint-disable no-extend-native */

String.prototype.hashCode = function() {
  var hash = 0, i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr   = this.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

if (!Array.prototype.indexOf){
  Object.defineProperty(Array.prototype, 'indexOf', {
    value: function (what, i) {
      i = i || 0;
      var L = this.length;
      while (i < L) {
        if (this[i] === what) return i;
        ++i;
      }
      return -1;
    },
    writable: false
  });
}

if (!Array.prototype.move){
  Array.prototype.move = function (from, to) {
    let temp = this[from];
    this[from] = this[to];
    this[to] = temp;
  };
}

if (!Array.prototype.clone){
  Array.prototype.clone = function(){
    return JSON.parse(JSON.stringify(this));
  }
}

if (!Array.prototype.remove){
  Object.defineProperty(Array.prototype, 'remove', {
    value: function () {
      var what;
      var a = arguments;
      var L = a.length;
      var ax;
      while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
          this.splice(ax, 1);
        }
      }
      return this;
    },
    writable: false
  });
}

String.prototype.capitalize = function (){
  return this.charAt(0).toUpperCase() + this.slice(1);
};

// definitions
const pages = {
  LANDING: 'landing',
  ABOUT_ME: 'aboutme',
  WORK_EXPERIENCE: 'workexp',
  PROJECTS: 'projects',
  PROJECT: 'project',
  EXTRAS: 'extras',
  ERROR: 'error'
};

// functions
function parseRoute (route){
  const path = route.split('/');
  path.remove('');
  if (path.length === 0){
    return pages.LANDING;
  }
  const page = path[0];
  switch (page){
    case 'aboutme':
      return pages.ABOUT_ME;
    case 'workexp':
      return pages.WORK_EXPERIENCE;
    case 'projects':
      return pages.PROJECTS;
    case 'project':
      return pages.PROJECT;
    case 'extras':
      return pages.EXTRAS;
    default:
      return pages.ERROR;
  }
}

const image_exts = [
  '.jpeg',
  '.jpg',
  '.png'
];

function scrollSideways(els) {
  for (const el of els){
    let ss_wrapper = function (event) {
      el.scrollLeft += event.deltaY + event.deltaX;
      event.preventDefault();
    }
    el.addEventListener("mouseenter", () => {
      window.addEventListener("wheel", ss_wrapper, {passive:false});
    })
    el.addEventListener("mouseleave", () => {
      window.removeEventListener("wheel", ss_wrapper);
    })
    el.addEventListener("click", () => {
      window.removeEventListener("wheel", ss_wrapper);
    }, {passive: true})
  }
}

// window definition
window.mobileCheck = function () {
  let check = false;
  (function (a) {
    // eslint-disable-next-line no-useless-escape
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}();

// export object

const utils = {
  PAGES: pages,
  parseRoute: parseRoute,
  IMAGE_EXTS: image_exts,
  scrollSideways
};

export default utils;
