<template>
  <div class="full-page on-top" @click="close">
    <div class="navbar-close">
     <div class="hamburger hamburger--collapse is-active">
        <div class="hamburger-box">
          <div class="hamburger-inner"></div>
        </div>
      </div>
    </div>
    <div class="gallery-show">
      <div class='h-100p' ref="imageScroller">
        <div class="scroll-container-inner" ref="scrollcontainer">
          <ImageFrame v-for="(link, k) in images" :key="k" :src="link" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageFrame from '../components/ImageFrame';
import SimpleBar from 'simplebar';
import sass from '../styles/_base.scss';


const logger_tag = 'PageGallery'

export default {
  name: logger_tag,
  components: {
    ImageFrame
  },
  props: {
    images: Array,
  },
  mounted: function(){
    this.$eventbus.emit('hideHamburger');

    new SimpleBar(this.$refs.imageScroller);
    if (window.matchMedia(`(max-width: ${sass.mobileWidth})`)) {
      new SimpleBar(this.$refs.scrollcontainer);
    }
  },
  beforeUnmount: function(){
    this.$eventbus.emit('showHamburger');
  },
  methods: {
    close: function(){
      this.$emit('close');
    }
  }
}
</script>

<style scoped lang="scss">
.navbar-close{
  position: absolute;
  top: 16px;
  right: 16px;
}

.gallery-show{
  margin-top: $navbar-height;
  height: calc(100% - #{$navbar-height});
  width: 100%;
}

.image-frame-container{
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 10px;
  margin-top: 10px;
}


</style>
