<template>
  <nav id="navbar" :class=navClasses>
      <div class="brand q-font f-medium">arun aruljothi</div>
      <router-link :class="{'active': currentPage['aboutme'] }" to="/aboutme"><div @click="toggleNav(true)">about me</div></router-link>
      <router-link :class="{'active': currentPage['workexp'] }" to="/workexp/1"><div @click="toggleNav(true)">experience</div></router-link>
      <router-link :class="{'active': currentPage['projects'] }" to="/projects"><div @click="toggleNav(true)">projects</div></router-link>
      <router-link :class="{'active': currentPage['extras'] }" to="/extras"><div @click="toggleNav(true)"><span><img class="extras-icon" src="../assets/fire_icon.svg"></span></div></router-link>
      <a v-if="!hideHamburger" @click="toggleNav(false)" href="javascript:void(0);" class="icon only-on-mobile">
        <div class="hamburger hamburger--collapse" :class="{'is-active': mobileNavOpen && !mobileNavClosing}">
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
        </div>
      </a>
  </nav>
</template>

<script>
import utils from '../lib/utilities';

export default {
  name: 'Navbar',
  props: {
    data: Object
  },
  data: function (){
    return {
      mobileNavOpen: false,
      mobileNavClosing: false,
      hideHamburger: false,
    };
  },
  mounted: function(){
    this.$eventbus.on('hideHamburger', function(){
      this.hideHamburger = true;
    }.bind(this));
    this.$eventbus.on('showHamburger', function(){
      this.hideHamburger = false;
    }.bind(this));
  },
  computed: {
    navClasses: function(){
      return {
        open: this.mobileNavOpen, 
        closing: this.mobileNavClosing, 
        extras: this.onExtrasPage, 
        "dark-mode": !this.data.darkMode && this.darkModePage
      }
    },
    onExtrasPage: function(){
      const page = utils.parseRoute(this.$route.path);
      return page === utils.PAGES.EXTRAS;
    },
    darkModePage: function(){
      const page = utils.parseRoute(this.$route.path);
      return page === utils.PAGES.PROJECT || page === utils.PAGES.ERROR;
    },
    currentPage: function(){
      let page = utils.parseRoute(this.$route.path);
      let activePage = {
        'aboutme': (page === utils.PAGES.ABOUT_ME),
        'workexp': (page === utils.PAGES.WORK_EXPERIENCE),
        'projects': (page === utils.PAGES.PROJECTS),
        'extras': (page === utils.PAGES.EXTRAS)
      }
      return activePage;
    }
  },
  methods: {
    toggleNav: function (onlyOpen = false){
      if (this.mobileNavOpen){
        this.mobileNavClosing = true;
        this.$eventbus.emit('NAVBAR_CLOSING');
        setTimeout(function (){
          this.mobileNavOpen = false;
          this.mobileNavClosing = false;
          this.$eventbus.emit('NAVBAR_CLOSED');
        }.bind(this), 400);
      } else if (!onlyOpen){
        this.mobileNavOpen = true;
        this.$eventbus.emit('NAVBAR_OPENING');
      }
    }
  }
};
</script>

<style lang="scss">
nav {
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: $navbar-font-size;
  padding: 24px 3vw 8px 3vw;
  height: $navbar-height;
  @include box-sizing(border-box);
  align-items: center;
  background-color: transparent;
  z-index: 999;

  .extras-icon{
    filter: invert(1);
  }

  .brand{
    font-size: calc(#{$navbar-font-size} * 1.2);
  }

  a {
    color: white;
    text-decoration: none;
    font-family: 'Inconsolata',monospace;
    font-weight: 400;
    &.active{
      text-decoration: underline;
    }
    &:not(.icon):active{
      text-decoration: underline;
    }
    &:hover{
      font-weight: 700;
    }
  }

  &.dark-mode{
    .extras-icon{
      filter: invert(0);
    }
    color: black;
    a{
      color: black;
    }
    &.open{
      a{
        color: white;
      }
    }
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after{
      background-color: black;
    }
  }

  // mobile styles
  @media only screen and (max-width: $mobile-width){
    flex-wrap: wrap;
    background-color: transparent;
    @include transition(background-color $fastest-animation-ms $default-animation-style);
    padding-top: 8px;

    a:not(.icon){
      visibility: hidden;
      order: 2;
      flex-basis: 100%;

      @for $i from 1 through 5 {
        &:nth-child(#{$i}){
          --animation-order: #{$i};
        }
      }
    }

    &.open{
      height: auto;
      background-color: #000000AF;
      z-index: 15;

      &.extras{
        background-color: #ffffff25;
      }

      a:not(.icon){
        display: block;
        visibility: visible;
        animation: animate-fade-in $fast-animation-ms $default-animation-style
                   calc(var(--animation-order) * 100ms) 1 normal both;
      }

      &.closing{
        background-color: transparent;
        a:not(.icon){
          animation: animate-fade-out $fastest-animation-ms $default-animation-style
                    0ms 1 normal both;
        }
      }
    }
  }
}
</style>
