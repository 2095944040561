<template>
  <div class="page" id="error-page">
    <div class="error-message" ref='mes'>{{error_message}}</div>
    <ButtonBox :buttons="linkButtons" class="col full"/>
  </div>
</template>

<script>
import ERROR_CODES from '../lib/error';
import ButtonBox from '../components/ButtonBox';

export default {
  name: 'ErrorPage',
  components: {
    ButtonBox,
  },
  props: {
    data: Object
  },
  data: function () {
    return {
      error_message: 'Page not found!',
    }
  },
  mounted: function(){
    if (this.data.darkMode){
      this.$refs.mes.classList.add('darkmode');
    }
    let route_mes = this.$route.params.pathMatch;
    if (route_mes != undefined && route_mes.length > 1){
      let error_code = route_mes[1];
      if (error_code in ERROR_CODES){
        this.error_message = ERROR_CODES[error_code];
      }else{
        this.error_message = ERROR_CODES[500];
      }
    }
  },
  computed: {
    linkButtons: function(){
      let buttons = [
        {
          name: 'go back home!',
          class: 'cb-blue1',
          to: '/aboutme'
        }
      ];
      return buttons;
    }
  }
};
</script>

<style scoped lang="scss">
#error-page{
  justify-content: center;
}
.error-message{
  padding-top:20%;
  font-size: 2rem;
  color: black;
  height: 100%;
}

.error-message.darkmode{
  color: white;
}

.button-box{
  align-self: center;
  min-width: 50%;
}
</style>
