import { marked } from 'marked';
import hljs from 'highlight.js';
import DOMPurify from 'dompurify';


function tocMarkedRenderer() {
  let toc = [];
  let anchors = [];
  let levelLabel = [0];
  let markdownRender = (function () {
    let renderer = new marked.Renderer();
    renderer.heading = function (text, level, raw) {
      let anchor = this.options.headerPrefix + raw.toLowerCase().replace(/[^\w]+/g, '-');
      anchor = `${anchor.hashCode()}--${anchor.replaceAll(" ", "_")}`;
      anchors.push(anchor);

      levelLabel = levelLabel.slice(0, level);
      if (levelLabel.length < level){
        levelLabel.push(0)
      }
      levelLabel[levelLabel.length - 1] += 1

      toc.push({
        anchor: anchor,
        level: level,
        text: text,
        levelLabel: levelLabel.join('.')
      });
      return `
      <h${level} id="${anchor}">
        ${text}
      </h${level}>\n`;
    };
    return renderer;
  })();
  this.toc = toc;
  this.renderer = markdownRender;
}

marked.setOptions({
  highlight: function (code, lang) {
    const language = hljs.getLanguage(lang) ? lang : 'plaintext';
    return hljs.highlight(code, {language: language}).value;
  },
  langPrefix: 'hljs language-',
  gfm: true,
  baseUrl: null,
  smartLists: true,
  smartypants: false
});

function updateMDImages(s, project_images){
  //eslint-disable-next-line
  let matches = s.matchAll(/\[.+\]\(\..+\)/g);
  for (const m of matches){
    let rp = m[0]; 
    rp = rp.split("(./");
    let image_name = rp[1].substr(0, rp[1].length-1);
    if (image_name in project_images){
      rp = rp[0] + "(" + project_images[image_name] + ")"
      s = s.replace(m, rp);
    }
  }
  return s;
}

function convertMarked(marked_str, project_images){
  let tocRenderer = new tocMarkedRenderer();
  marked.setOptions({
    renderer: tocRenderer.renderer
  });
  let _marked_str = project_images ? updateMDImages(marked_str, project_images) : marked_str;
  _marked_str = _marked_str.replaceAll('\\n', '\n');
  let content_html = marked(_marked_str);
  return {
    toc: tocRenderer.toc,
    html: DOMPurify.sanitize(content_html)
  }
}

export default convertMarked;