<template>
  <div class="page" id="workexp-page">
    <div class="only-on-mobile">
      <transition name="vfade" mode="out-in">
        <PageGallery v-if="galleryOpen" @close="galleryOpen=False" :images="currentImages" />
      </transition>
    </div>
    <div id='workexp-top' class="page-top">
      <div class="off-page-link-box" ref="headerOffpageBox">
        <div class="workexp-header-wrapper" v-for="(header, index) in workExps" :key="index" :data-id="index" >
          <div class="workexp-header" @click="navigateToIndex(index, $event)" :class="{active: currentIndex==index+1}">
            <span class='title q-font f-medium'>
              {{header.title}}
            </span>
            <span class="company q-font f-regular">
              {{header.company}}
            </span>
            <span class="location q-font f-light">
              {{header.location}}
            </span>
            <span class="duration q-font f-light">
              {{header.start}} - {{header.end}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div id="workexp-bottom" class="page-bottom dflex row">
      <div class="scroll-image-container only-on-desktop" :class="{hideme: currentImages==0}">
        <div class='h-100p' ref="imageScroller">
          <div class="scroll-container-inner ">
            <ImageFrame v-for="(link, k) in currentImages" :key="k" :src="link" />
          </div>
        </div>
      </div>
      <div class="text-info-container" :class="{maximize: currentImages==0}">
        <div class="text-box q-font" ref='textBox'>
          <div class="text-box-banner s-font f-bold pad-bottom">
            <span>Background</span>
            <span class="only-on-mobile gallery-btn" v-show="currentImages!=0" @click="showGallery">Open Gallery</span>
          </div>
          {{currentWork.description}}
          <br>
          <br>
          <span class="s-font f-bold pad-bottom">Highlights</span>
          <div class='task' v-for="task in currentWork.tasks" :key="task">• {{task}}</div>
        </div>
        <ButtonBox :buttons="linkButtons" class="full" direction="col"/>
      </div>
    </div>
  </div>
</template>

<script>
const logger_tag = 'WorkExpPage';
import utils from '../lib/utilities';
import ButtonBox from '../components/ButtonBox';
import ImageFrame from '../components/ImageFrame';
import PageGallery from '../components/PageGallery';
import SimpleBar from 'simplebar';
import sass from '../styles/_base.scss';


export default {
  name: logger_tag,
  components: {
    ButtonBox,
    ImageFrame,
    PageGallery
  },
  props: {
    links: Object,
    data: Object
  },
  data: function(){
    return{
      galleryOpen: false
    }
  },
  created: function(){
    if (this.currentIndex > this.workExps.length){
      this.$router.push('/error/404');
    }
  },
  mounted: function(){

    new SimpleBar(this.$refs.imageScroller);
    if (window.matchMedia(`(max-width: ${sass.mobileWidth})`)) {
      new SimpleBar(this.$refs.textBox);
    }

    // scroll component
    (() => {
      const targets = document.querySelectorAll(".workexp-header-wrapper");
      const options = {threshold: 0.99}
      const inView = target => {
        const interSecObs = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if(entry.isIntersecting) {
              this.navigateToIndex(parseInt(entry.target.dataset.id));
            }
          });
        }, options);
        interSecObs.observe(target);
      };
      targets.forEach(inView);
    })();

  },
  computed: {
    workExps: function(){
      let ret = Object.fromEntries(this.data.workexp.map(x => [x.id, x]));
      let arr = [];
      for (let i=1; i< this.data.workexp.length+1; i++){
        arr.push(ret[i]);
      }
      return arr;
    },
    linkButtons: function(){
      let index = this.$route.params.id;
      let currentLinks = this.currentWork.links;
      let buttons = [];
      for (const link of currentLinks){
        let href = link.href;
        if (link.href.includes('storage/')){
          let name = href.split("storage/")[1]
          href = this.links.workexp[index][name];
        }
        buttons.push({
          'name': link.title,
          'link': href,
          'class': 'cb-blue1'
        });
      }
      return buttons;
    },
    currentImages: function(){
      let index = this.$route.params.id;
      let linkHasCorrectExt = (l) => utils.IMAGE_EXTS.some((e) => l.includes(e));
      let imageLinks = Object.keys(this.links.workexp[index]).filter(l => linkHasCorrectExt(l));
      return imageLinks.map(l => this.links.workexp[index][l])
    },
    currentWork: function(){
      let index = this.$route.params.id - 1;
      return this.workExps[index];
    },
    currentIndex: function(){
      return this.$route.params.id;
    }
  },
  methods: {
    showGallery: function(){
      this.galleryOpen = true;
    },
    hideGallery: function(){
      this.galleryOpen = false;
    },
    navigateToIndex: function(index, event){
      if (event != undefined){
        event.currentTarget.scrollIntoView({
          inline: 'center',
          behavior: 'smooth'
        }); 
      }
      if (this.$refs.imageScroller !== undefined){
        this.$refs.imageScroller.scrollTop = 0;
      }
      this.$router.push(
        { name: utils.PAGES.WORK_EXPERIENCE,
          params: { id: index + 1}
        }
      );
    }
  }
};
</script>

<style scoped lang="scss">
$bottom-top-padding: 2.5rem;
$bottom-top-padding-mobile: 0.5rem;


.pad-bottom{
  display: inline-block;
  padding-bottom: 5px;
}
#workexp-bottom{
  padding-top: $bottom-top-padding;
  max-height: calc(100% - (#{$workexp-top-height} * 100%) - #{$bottom-top-padding});
  justify-content: space-evenly;

  .text-box-banner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .gallery-btn{
    @include no-select;
    color: $c-green1;
    &:active{
      text-decoration: underline;
    }
  }

  .scroll-image-container{
    width: 45%;
    max-height: 100%;

    &.hideme{
      display: none !important;
    }

    .image-frame-container{
      max-height: calc(60% - (1/8 * 100%));
    }

    .scroll-container-inner{
      > :first-child{
        margin-top: 5px;
      }
      > .image-frame-container:not(:first-child) {
        margin-top: calc(1/10 * 100%);
      }
      > :last-child{
        margin-bottom: 10px;
      }
    }
  }
  .text-info-container{
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include selection-color($c-lighter-subtle);

    &.maximize{
      width: 80%;
    }

    .text-box{
      min-height:80%;
      font-size: 1.1rem;
      text-align: justify;
      .task{
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
    .link-box{
      flex-grow: 1;
      z-index: 9999;
      margin-bottom: 3px;
    }
  }

  @media only screen and (max-width: $mobile-width){
    padding-top: $bottom-top-padding-mobile;
    max-height: calc(100% - (#{$workexp-top-height} * 100%) - #{$bottom-top-padding-mobile});

    .text-info-container{
      width: 90%;
      &.maximize{
        width: 90%;
      }

      .text-box{
        max-height: 100%;
        font-size: .9rem;
      }
      .link-box{
        margin-top: .5rem;
      }
    }
  }



}

.workexp-header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  height: 100%;
  cursor: pointer;

  border-bottom: solid 5px $c-underlight;
  @include box-sizing(border-box);

  @include selection-color(white);

  span{
    text-align: start;
  }
  .title{
    font-size: 36px;
    color: $c-text-primary;
  }
  .company{
    font-size: 28px;
  }
  .location,
  .duration{
    font-size: 20px;
  }

  @media only screen and (max-width: $mobile-width){
    .title{
      font-size: 1.4rem;
      color: $c-text-primary;
    }
    .company{
      font-size: 1.1rem;
    }
    .location,
    .duration{
      font-size: 1.1rem;
    }
  }

}

.button-box{
  gap: 3px;
  height: auto !important;
  overflow-y: visible;
}
</style>
