
// import router from "./routes";

// error codes to strings
let ERROR_CODES = {
  404: 'Page not found!',
  500: 'Unkown error occured!'
};

// // have errors redirect to error page
// (function () {
//   const err = console.error.bind(console);
//   console.error = (...args) => {
//     err(...args);
//     router.push(`/error/${args[0]}`);
//   }
// }.bind(router)());

export default ERROR_CODES;
