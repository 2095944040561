<template>
  <Background v-if="!onLandingPage" :data=data />
  <Navbar v-if="!onLandingPage" :data=data />
  <router-view v-slot="{ Component }">
    <transition name="vfade" mode="out-in">
      <component :is="Component" :data=data :links=links />
    </transition>
  </router-view>
</template>

<script>
import utils from './lib/utilities';
import { DB_FUNCS, STORAGE_FUNCS } from './lib/firebase';
import Background from './components/Background';
import Navbar from './components/Navbar';
import 'simplebar/dist/simplebar.css';
import sass from './styles/_base.scss';

// Set app color here --------
const darkModeBG = sass.darkModeBG;
const darkModeFG = sass.darkModeFG;
const lightModeBG = sass.lightModeBG;
const lightModeFG = sass.lightModeFG;

export default {
  name: 'App',
  components: {
    Background,
    Navbar
  },
  data: function (){
    return {
      data: {
        loadedPercent: 0,
        onLoaded: 'aboutme',
        bgColor: lightModeBG,
        fgColor: lightModeFG,
        darkMode: false,
        mobile: false
      },
      links: {}
    };
  },
  mounted: function (){
    this.$logger.log('app mounted...');

    // on mobile
    this.data.mobile = window.screen.availWidth <= parseInt(sass.mobileWidth);
    if (this.data.mobile){
      this.appRef.classList.add('app-mobile');
    }else{
      this.appRef.classList.add('app-desktop');
    }
    
    // dark mode
    this.setColorScheme((window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches));
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      this.setColorScheme(e.matches);
    })

    // load all storage
    const load = async function (){
      this.data.loadedPercent = 0;
      for (const name of ['aboutme', 'workexp', 'projects']){
        const funcName = `get${name.capitalize()}`;
        this.data[name] = await DB_FUNCS[funcName]();
        this.data.loadedPercent += 100/6;
        if (name == 'projects'){
          let proj_folders = [];
          let dict_proj = {}
          this.data['projects'].forEach((proj) =>{
            proj_folders.push(proj.id);
            dict_proj[proj.id] = proj;
          })
          this.data['projects'] = dict_proj;
          this.links[name] = await STORAGE_FUNCS[funcName](proj_folders);
        }else{
          this.links[name] = await STORAGE_FUNCS[funcName]();
        }
        this.data.loadedPercent += 100/6;
      }
      this.data.loadedPercent = 100;
    }.bind(this);
    load();
  },
  methods: {
    setColorScheme: function(darkMode){
      if (darkMode){
        this.data.bgColor = darkModeBG;
        this.data.fgColor = darkModeFG;
        this.data.darkMode = true;
      }else {
        this.data.bgColor = lightModeBG;
        this.data.fgColor = lightModeFG;
        this.data.darkMode = false;
      }
      document.body.style.backgroundColor = this.data.bgColor;
      document.body.style.color = this.data.fgColor;
    }
  },
  computed: {
    onLandingPage: function (){
      const page = utils.parseRoute(this.$route.path);
      return page === utils.PAGES.LANDING;
    },
    dataLoaded: function(){
      return this.data.loadedPercent == 100;
    },
    appRef: function(){
      return document.getElementById('app');
    }
  },
  watch: {
    dataLoaded: function(){
      if (this.dataLoaded){
        this.$router.appLoaded = true;
      }else{
        this.$router.appLoaded = false;
      }
    }
  }
};
</script>

<style lang="scss">
@import "./style.scss";
@import "./styles/hamburgers.css";
</style>
