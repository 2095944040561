// top level imports
import { createRouter, createWebHistory } from 'vue-router'
import logger from './logger'
import utils from './utilities'

const log_tag = 'ROUTER';

const lost_route = '/error/404';

// page level components
import LandingPage from '../views/LandingPage.vue';
import AboutMePage from '../views/AboutMePage.vue';
import ExtrasPage from '../views/ExtrasPage.vue';
import ProjectsPage from '../views/ProjectsPage.vue';
import WorkExpPage from '../views/WorkExpPage.vue';
import ErrorPage from '../views/ErrorPage.vue';
import SingleProjectPage from '../views/SingleProjectPage.vue';

const routes = [
  { path: '/', component: LandingPage, name:utils.PAGES.LANDING },
  { path: '/aboutme', component: AboutMePage, name:utils.PAGES.ABOUT_ME },
  { path: '/workexp/:id', component: WorkExpPage, name:utils.PAGES.WORK_EXPERIENCE },
  { path: '/projects', component: ProjectsPage, name:utils.PAGES.PROJECTS },
  { path: '/project/:id', component: SingleProjectPage, name:utils.PAGES.PROJECT},
  { path: '/extras', component: ExtrasPage, name:utils.PAGES.EXTRAS },
  { path: '/error/:pathMatch(.*)*', component: ErrorPage, name:utils.PAGES.ERROR },
  { path: '/:pathMatch(.*)*', component: ErrorPage, name:utils.PAGES.ERROR }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// same-component redirects only
const path_redirects = {
  '/workexp': '/workexp/1',
  '/workexp/0': '/workexp/1',
  '/project/0': '/projects',
  '/project': '/projects'
}

const component_redirects = {
  'error': { not_match: '/error', direct_to: lost_route }
}

router.beforeEach((to, from, next) => {
  logger.log(`router moving from ${from.fullPath} to ${to.fullPath}`, log_tag, logger.levels.INFO);
  // check path redirects
  if (to.path in path_redirects){
    to.path = path_redirects[to.path];
  }
  // check component redirect
  if ((to.matched[0].name in component_redirects) &&
      (to.path.toLowerCase().indexOf(component_redirects[to.matched[0].name].not_match) === -1)){
        to.path = component_redirects[to.matched[0].name].direct_to;
  }
  // check index guards
  if (to.name == utils.PAGES.PROJECT || to.name == utils.PAGES.WORK_EXPERIENCE){
    if (to.params.id != String(parseInt(to.params.id))){
      let path = to.path.split('/').slice(0, -1);
      path.push(parseInt(to.params.id));
      to.path = path.join('/');
    }
  }
  // data loaded guard
  if (to.name!=utils.PAGES.LANDING && !router.appLoaded){
    router.lastLoaded = to;
    next('/');
    return;
  }
  next();
})

// global guard for app loaded
router.appLoaded = false;
router.lastLoaded = routes[0];

export default router;
