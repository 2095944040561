<template>
  <div class="loading-bar" :style="{borderColor: borderColor}">
    <div id="hiding-bar" :style="{width: currentLoad+'%', backgroundColor: loadingColor}" ></div>
    <div class="text i-font f-regular">{{currentText}}</div>
  </div>
</template>

<script>

export default {
  name: 'LoadingBar',
  props: {
    texts: Array,
    minimumWaitSeconds: Number,
    currentPercent: Number,
    loadingColor: String
  },
  data: function (){
    return {
      currentText: '',
      currentLoad: 0,
      borderColor: 'black'
    };
  },
  computed: {
  },
  mounted: function (){
    if (this.loadingColor !== 'white'){
      this.borderColor = 'white';
    } else {
      this.borderColor = 'black';
    }

    const minWait = (this.minimumWaitSeconds === undefined) ? 2 : this.minimumWaitSeconds;
    // fire update function
    const updateBar = function (){
      let updateFrameCount = 0;
      const frameRate = 60; // 60 calls per second
      const updateTimer = setInterval(function (){
        const input = (this.currentPercent === undefined) ? 9999999 : this.currentPercent;
        const currValue = Math.min(updateFrameCount / (minWait * frameRate) * 100, input);
        if (currValue === 100){
          clearInterval(updateTimer);
          setTimeout(function (){
            this.$emit('complete');
          }.bind(this), 200);
        }
        this.currentLoad = 100 - currValue;
        updateFrameCount += 1;
      }.bind(this), 16.666);
    }.bind(this);
    updateBar();
  }
};
</script>

<style scoped lang="scss">

.loading-bar{
  margin-top: 15px;
  height: 40px;
  width: auto;
  margin-left: -6%;
  margin-right: -6%;
  background: $background;
  position: relative;
  border-radius: 3px;
  border: 1px solid;
  border-color: black;

  #hiding-bar{
    background-color: black;
    content: '';
    height: 40px;
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
    width: 0%;
    // border-radius: 0px 3px 3px 0px;
  }

  &.all::before{
    border-radius: 3px;
  }

  .text{
    display: inline-block;
    vertical-align: middle;
    font-size: 36px;
    height: 100%;
    color: white;
    z-index: 100;
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
  }

}

</style>
